<script>
export default {
  name: 'Footer'
}
</script>

<template>
  <div class="footer-area-wrapper reveal-footer theme_bg">
    <div class="section-space--pt_30">
      <div class="container">
        <div class="row aling-items-center">
          <div class="col-md-12">
            <div class="horizontal-list text-center">
              <h6>
                نطمح لأن نكون مؤسسة قرآنية جامعة وفاعلة ومعتمدة ومميزة تقدم
                خدمات نوعية وشاملة في مجال علوم القرآن الكريم لتمثل تجربة رائدة
                في العمل الإسلامي النسوي.
              </h6>
              <br />
              <span class="hover-style-link--white">
                &copy; 2024 <b>المجمع القرآني النسوي</b> في البحرين
              </span>
              <br />
              <br />
              <a
                href="tel:37797911"
                class="hover-style-link hover-style-link--white"
                >37797911</a
              >
              |
              <a
                href="tel:39727915"
                class="hover-style-link hover-style-link--white"
                >39727915</a
              >
              <br />
              <a
                href="mailto:m.qurani.n.bh@gmail.com"
                class="hover-style-link hover-style-link--white"
                >m.qurani.n.bh@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
